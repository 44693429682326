<template>
  <div class="flex flex-col gap-y-10">
    <client-only>
      <template #fallback>
        <SkeletonsHeroBanner></SkeletonsHeroBanner>
      </template>

      <PageBuilder
        v-if="layout && !isLoading"
        :layout="layout"
        :kind="kind"
      ></PageBuilder>
    </client-only>
  </div>
</template>

<script setup>
import { PAGES_IDENTIFIER } from "@/constants/index";
const kind = ref("sports");
const { t } = useI18n();

const { data: layout, pending: isLoading } = await useAsyncData(
  "layout",
  async () => {
    return await fetchLayout("/api/biz/config/v1/carousels", {
      page: kind.value,
    });
  },
  {
    server: false,
  }
);

// Define Page Name
definePageMeta({
  pageType: PAGES_IDENTIFIER.SPORTS,
});

onBeforeMount(async () => {
  console.log("load fma-->", usePageId().ADULT__SPORTS);
  await useFmaSdk().logPageNav(usePageId().ADULT__SPORTS);
});

onMounted(async () => {
  await useFmaSdk().loaded();
});

const runtimeConfig = useRuntimeConfig();
useSeoMeta({
  title: () => `${t("sports_main_category_page_title")}`,
  ogTitle: () =>
    `${t("sports_main_category_page_title")} | ${
      runtimeConfig.public.mdaApplicationName
    }`,
  description: () => `${t("sports_main_category_page_description")}`,
  ogDescription: () => `${t("sports_main_category_page_description")}`,
});

onBeforeUnmount(() => {
  useFmaSdk().terminate();
});
</script>

<script>
export default { name: "SportsIndexPage" };
</script>

<style lang="scss" scoped></style>
